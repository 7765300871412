/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'swiper/css/bundle';

*{
  // --pdf-height: calc(100vh - 34px - 42px);
  --pdf-height: 590px;

}

ion-modal.details-modal{
  --width: 100% !important;
  --min-height: 100% !important;
  align-items: flex-end;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h.details-modal{
  --width: 100% !important;
  --min-height: 100% !important;
  align-items: flex-end;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h:first-of-type.details-modal{
  --width: 100% !important;
  --min-height: 100% !important;
  align-items: flex-end;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

#viewerContainer{
  margin-left: auto;
  margin-right: auto; ;
  width: calc(var(--pdf-height)/3*4);
  height: var(--pdf-height);
  margin-bottom: 1rem;
}

#mainContainer{
  height: 100%;
}

#outerContainer{
  height: 100%;
}

ngx-extended-pdf-viewer{
  height:  100%;
}

ion-modal.fabbisogni-modal{
  --width: 65% !important;
  --height: 85% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h.fabbisogni-modal{
  --width: 65% !important;
  --height: 85% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h:first-of-type.fabbisogni-modal{
  --width: 65% !important;
  --height: 85% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.fabbisogni-modal{
  --border-radius: 30px;
}

ion-modal.fabbisogni-modal-autosuff{
  --width: 65% !important;
  --height: 65% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h.fabbisogni-modal-autosuff{
  --width: 65% !important;
  --height: 65% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h:first-of-type.fabbisogni-modal-autosuff{
  --width: 65% !important;
  --height: 65% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.fabbisogni-modal-autosuff{
  --border-radius: 30px;
}

ion-modal.fabbisogni-modal-premorienza{
  --width: 65% !important;
  --height: 80% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h.fabbisogni-modal-premorienza{
  --width: 65% !important;
  --height: 80% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h:first-of-type.fabbisogni-modal-premorienza{
  --width: 65% !important;
  --height: 80% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.fabbisogni-modal-premorienza{
  --border-radius: 30px;
}

ion-modal.fabbisogni-modal-dream{
  --width: 90% !important;
  --height: 65% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h.fabbisogni-modal-dream{
  --width: 90% !important;
  --height: 65% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h:first-of-type.fabbisogni-modal-dream{
  --width: 90% !important;
  --height: 65% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.fabbisogni-modal-dream{
  --border-radius: 30px;
}



ion-modal.details-modal{
  --width: 90% !important;
  --height: 50% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h.details-modal{
  --width: 90% !important;
  --height: 50% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sc-ion-modal-ios-h:first-of-type.details-modal{
  --width: 90% !important;
  --height: 50% !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.details-modal{
  --border-radius: 30px;
}

.details-modal-babysitter{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 88% !important;
  align-items: center;
}

.details-modal-compiti{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 88% !important;
  align-items: center;
}

.details-modal-domestici{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 88% !important;
  align-items: center;
}

.details-modal-psicologo{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 57% !important;
  align-items: center;
}

.details-modal-manutenzione{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 75% !important;
  align-items: center;
}

.details-modal-soluzioni-ideali{
  --border-radius: 30px;
  --width: 95% !important;
  --height: 70% !important;
  align-items: center;
}

.details-modal-soluzioni-ideali-popup{
  --border-radius: 30px;
  --width: 95% !important;
  --height: 74% !important;
  align-items: center;
}

.details-modal-studio{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 85% !important;
  align-items: center;
}

.details-modal-casa{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 90% !important;
  align-items: center;
}

.modal-shadow.sc-ion-modal-ios {
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 40px 10px;
}

// ion-toggle {
//   outline: 2px solid white;
//   border-radius: 50vh;
// }

ion-toggle {
  // --handle-width: 25px;
  // --handle-height: 27px;
  // --handle-max-height: auto;
  // --handle-spacing: 6px;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
  background: var(--c-white);
  -moz-box-shadow:    inset 3px 3px 8px -6px #000000;
  -webkit-box-shadow: inset 3px 3px 8px -6px #000000;
  box-shadow:         inset 3px 3px 8px -6px #000000;
}

ion-toggle::part(handle) {
  background: #00000070;

  box-shadow: none;
}

ion-toggle.toggle-checked::part(handle) {
  background: var(--c-completed);
}

i.fa {
  -webkit-animation: show 1s 1;
  /* any other properties to override from FontAwesome's default .fa rule */
}
@-webkit-keyframes show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.color-select{
  color: var(--c-casa);

  .mat-select-arrow {
    color: var(--c-casa) !important;
  }

  .mat-select-min-line {
    color: var(--c-casa);
  }
}
